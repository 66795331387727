/**
 *    var flexible = new Flexible({
 *      // 基准大小
 *      baseSize: 100,
 *      // 最大调节宽度
 *      maxWidth: 540,
 *      // 设计稿宽度
 *      designWidth: 375,
 *  });
 **/
const Flexible = function(options) {

	options = options || {};

	this.baseSize = options.baseSize || 100;
	// 最大调节宽度
	this.maxWidth = options.maxWidth || 540;
	// 设计稿宽度
	this.designWidth = options.designWidth || 375;
	// 获取html根标签
	this.element = document.documentElement;

	// 监听浏览器调整大小
	window.addEventListener("resize", (event) => {
		this.refresh();
	});

	// 监听浏览器后退
	window.addEventListener("pageshow", (event) => {
		if (event.persisted) {
			this.refresh();
		}
	});

	// 初始化根标签字体大小
	this.refresh();
};

// 刷新比例
Flexible.prototype.refresh = function() {
	let clientWidth = this.element.clientWidth;

	if (clientWidth > this.maxWidth) {
		clientWidth = this.maxWidth;
	}

	this.element.style.fontSize = (clientWidth * this.baseSize / this.designWidth) + "px";
};


export default Flexible
