import Config from "@/vendor/config"
import Storage from "@/vendor/storage"

const prefix = "_DISTRICT_";

export default {
	setDistrict(district) {
		Storage.localStorage.set(prefix, district);
	},
	getDistrict() {
		return Storage.localStorage.get(prefix);
	},
	getDistrictId() {
		let district = this.getDistrict();
		return district ? district.id : Config.defaultDistrict.id
	},
	getDistrictType() {
		let district = this.getDistrict();
		return district ? district.type : Config.defaultDistrict.type
	},
	getDistrictName() {
		let district = this.getDistrict();
		return district ? district.name : Config.defaultDistrict.name
	},
	getDefaultDistrictId() {
		return Config.defaultDistrict.id;
	}
}
