class WebSocketIO {

	/**
	 * 配置
	 */
	options = {}

	/**
	 * 连接实例
	 */
	webSocket = null

	/**
	 * 连接状态
	 */
	status = false

	/**
	 * 已重连次数
	 */
	reconnectionAttempts = 0

	/**
	 * 监听事件
	 */
	eventList = {
		// 连接建立时
		onOpen: {},
		// 连接重载时
		onReload: {},
		// 收到消息时
		onMessage: {},
		// 连接关闭时
		onClose: {},
		// 连接错误时
		onError: {},
		// 当重连达到最大次数时
		reconnectionMax: {}
	}

	/**
	 * 初始化
	 * @param {Object} options
	 */
	constructor(options) {

		const defaults = {
			// 连接地址 ws://127.0.0.1:7272
			connection: "",
			// 数据格式化类型：raw-原生格式, json-目前仅支持
			formatType: "json",
			// 是否重连
			reconnection: true,
			// 重连尝试次数：Infinity-不限制重连次数
			reconnectionAttempts: 5,
			// 重连等待时间
			reconnectionDelay: 1000
		}

		// 初始化配置
		this.options = Object.assign({}, defaults, options);

		// 创建连接
		this.createWebSocket();
	}

	/**
	 * 创建实例
	 */
	createWebSocket() {
		this.webSocket = new WebSocket(this.options.connection);

		this.webSocket.onopen = () => {
			this.onOpen();
		}

		this.webSocket.onmessage = (event) => {
			this.onMessage(event);
		}

		this.webSocket.onclose = (event) => {
			this.onClose(event);
		}

		this.webSocket.onerror = (event) => {
			this.onError(event)
		}
	}

	/**
	 * 发送数据
	 * @param {Object} params
	 */
	sendData(params) {

		// 格式化处理
		if (this.options.formatType == 'json') {
			params = this.jsonToString(params)
		}

		this.webSocket.send(params);
	}

	/**
	 * 监听连接打卡
	 */
	onOpen() {
		// 检测是重连还是重载
		if (this.reconnectionAttempts > 0) {
			this.dispatch('onReload');
		} else {
			this.dispatch('onOpen');
		}

		this.status = true;
		this.reconnectionAttempts = 0;
	}

	/**
	 * 监听服务端消息
	 * @param {Object} event
	 */
	onMessage(event) {

		let data = event;

		if (this.options.formatType == 'json') {
			data = this.stringToJson(event.data);
		}

		this.dispatch('onMessage', data);
	}

	/**
	 * 监听连接关闭
	 * @param {Object} event
	 */
	onClose(event) {
		// 设置连接状态为不可用
		this.status = false;

		// 触发连接关闭事件
		this.dispatch('onClose');

		// 检测是否开启重连
		if (this.options.reconnection) {

			// 检测是否大于重连最大次数
			if (this.reconnectionAttempts < this.options.reconnectionAttempts) {

				this.reconnectionAttempts++;

				setTimeout(() => {
					this.createWebSocket();
				}, this.options.reconnectionDelay);

			} else {
				// 触发超出最大重连次数事件
				this.dispatch('reconnectionMax');
			}
		}
	}

	/**
	 * 监听连接错误
	 * @param {Object} event
	 */
	onError(event) {
		this.dispatch('onError');
	}

	/**
	 * 添加事件监听
	 * @param {Object} eventName
	 */
	addListener(eventName, keyName, callback) {
		this.eventList[eventName][keyName] = callback;
	}

	/**
	 * 移除事件监听
	 * @param {Object} eventName
	 */
	removeListener(eventName, keyName) {
		delete this.eventList[eventName][keyName];
	}

	/**
	 * 事件调度
	 */
	dispatch(eventName, params) {
		for (let index in this.eventList[eventName]) {
			typeof this.eventList[eventName][index] == 'function' && this.eventList[eventName][index](params);
		}
	}

	/**
	 * 关闭连接
	 * @param bool reconnection 是否自动重连
	 */
	destroy() {
		this.webSocket.close();
		this.webSocket = null;
	}

	/**
	 * 是否准备就绪
	 */
	isReady() {
		return this.status
	}

	/**
	 * 准备就绪
	 */
	onReady(callback) {
		if (this.isReady()) {
			typeof callback == 'function' && callback();
		} else {
			setTimeout(() => {
				this.onReady(callback);
			}, 10);
		}
	}

	/**
	 * 字符串转json
	 */
	stringToJson(data) {
		return JSON.parse(data);
	}

	/**
	 * json转字符串
	 */
	jsonToString(data) {
		return JSON.stringify(data);
	}
}

export default WebSocketIO