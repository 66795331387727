import Storage from "@/vendor/storage"

import DistrictHelper from "@/vendor/district"

const getClassName = (type) => {
	if (type == 1) {
		return "city";
	}
	return "district";
}

let currentDistrictType = DistrictHelper.getDistrictType();

export default {
	namespaced: true,
	state: {
		type: currentDistrictType,
		className: getClassName(currentDistrictType)
	},
	mutations: {
		setDistrict(state, district) {
			state.type = district.type;
			state.className = getClassName(district.type);
			DistrictHelper.setDistrict(district);
		}
	}
}
