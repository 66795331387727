const group = {
	namespaced: true,
	state: {
		group: []
	},
	mutations: {
		setGroup(state, group) {
			state.group = group
		},
		setMessageByGroupId(state, data) {
			let index = state.group.findIndex(e => {
				return e.id == data.department_id
			})
			
			state.group[index].message = data
			state.group[index].last_time = data.create_time
		},
		setUnreadByGroupId(state, groupId) {
			let index = state.group.findIndex(e => {
				return e.id == groupId
			})

			state.group[index].unread = true
		},
		clearUnreadByGroupId(state, groupId) {
			let index = state.group.findIndex(e => {
				return e.id == groupId
			})

			if (index !== -1) {
				state.group[index].unread = false
			}
		},
		deleteGroupById(state, groupId) {
			let index = state.group.findIndex(e => {
				return e.id == groupId
			})

			state.group.splice(index, 1);
		}
	}
}

export default group
