<template>
	<div class="NavTab">
		<div class="head-top">
			<div class="head-icon" @click="goBack">
				<van-icon name="arrow-left" size="20"/>
			</div>
			<div class="head-title">{{titleText}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			titleText: {
				default: ''
			}
		},
		data() {
			return{
				
			}
		},
		created() {
			
		},
		methods:{
			goBack(){
				if(window.history.length<=2){
					this.$router.push('/')
				}else{
					this.$router.go(-1)
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.NavTab{
		background: #FFF;
		padding: 13px 17px;
		border-bottom: 1px solid #f2f2f2;
		box-sizing: border-box;
		.head-top{
			position: relative;
			.head-icon{
				position: absolute;
				left: 0;
			}
			.head-title{
				font-weight: bold;
				font-size: 17px;
				line-height: 20px;
				color: #1C1D1D;
				text-align: center;
			}
		}
	}
</style>