import Vue from "vue"
import Store from "@/store/store"
import VueRouter from "vue-router"
import TokenHelper from '@/vendor/token';
import ActionVendor from '@/vendor/action';
import StorageVendor from "@/vendor/storage"

Vue.use(VueRouter)

import Main from "@/router/main"

const router = new VueRouter({
	routes: Main,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})

router.beforeEach((to, from, next) => {
	
	// 登录成功
	if (to.query.token) {
		let redirect = StorageVendor.localStorage.get('redirect');
		TokenHelper.setToken(to.query.token);
		StorageVendor.localStorage.delete('redirect');
		if (redirect != null) {
			return next(redirect);
		}
		return next({ name: 'Index' });
	}

	// token不存在
	if (TokenHelper.getToken() == null) {
		ActionVendor.jumpToLogin(to.fullPath);
		return next(false);
	}

	if (Store.state.user.userInfo.status == 2) {
		return next({
			name: "Blacklist"
		});
	}

	// 其他
	next()
})

export default router