
import Router from "@/router/router"
import Config from "@/vendor/config"
import Global from "@/vendor/global"
import VantVendor from "@/vendor/vant"
import StorageVendor from "@/vendor/storage"

/**
 * 跳转登录
 */
const jumpToLogin = (fullPath) => {
	if (Global.isLoging == false) {

		Global.isLoging = true;
		
		fullPath = fullPath || Router.currentRoute.fullPath;
		
		if (fullPath != '/') {
			StorageVendor.localStorage.set('redirect', fullPath);
		}

		location.replace(Config.loginUrl);
	}
}

/**
 * 注册询问
 */
const showRegisterConfirm = () => {
	VantVendor.Toast.clear();
	VantVendor.Dialog.confirm({
		title: '您还未注册',
		message: '请注册后再进行操作',
		confirmButtonText: '去注册',
		cancelButtonText: '暂不注册'
	}).then(() => {
		Router.push({
			name: 'Register'
		});
	}).catch(() => {
		console.log('用户拒绝去注册');
	})
}

const showApplyConfirm = () => {
	VantVendor.Dialog.confirm({
		title: '提示',
		message: '请先申请成为平安志愿者或平安义警',
		confirmButtonText: '去申请',
		cancelButtonText: '暂不申请'
	}).then(() => {
		Router.push({
			name: 'ApplyVigilante'
		});
	}).catch(() => {
		console.log('用户拒绝去申请');
	})
}

const showRenewalConfirm = () => {
	VantVendor.Dialog.confirm({
		title: '提示',
		message: '请完善好您的基本信息后再参与活动报名',
		confirmButtonText: '立即更新',
		cancelButtonText: '下次再说'
	}).then(() => {
		Router.push({
			name: 'ModifyInfo'
		});
	}).catch(() => {
		console.log('用户拒绝去更新');
	})
}

const showPermission = () => {
	VantVendor.Dialog.alert({
		title: '提示',
		message: '您的账号权限不足，无法访问！',
	}).then(() => {
		Router.replace({
			name: 'Index'
		});
	})
}

const jumpArea = () =>{
	VantVendor.Dialog.confirm({
		title: '提示',
		message: '您确认跳转到信州区平台吗？',
	}).then(() => {
		window.location.replace('http://xzyjv2.web.zyrlzy.cn/#/');
	})
}

export default {
	jumpToLogin,
	showApplyConfirm,
	showRegisterConfirm,
	showRenewalConfirm,
	showPermission,
	jumpArea
}
