export default {
	namespaced: true,
	state: {
		shopping: {},
	},
	mutations: {
		setShopping(state, shopping) {
			state.shopping = shopping
		},
		updateShopping(state, key, value) {
			state.shopping[key] = value
		},
		clearShopping(state) {
			state.shopping = {}
		}
	}
}