import Config from "@/vendor/config"
import Request from "@/api/request.js"


// 登录日志
const loginLog = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/loginLog', params)
}

// 用户信息
const getUserInfo = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/userInfo', params)
}
// 用户首页 
const getUserCenter = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/index', params)
}
// 爱心值等级详情
const getLoveLevelDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/loveLevelDetail', params)
}
// 用户基本信息 
const getUserBasicInfo = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/userBasicInfo', params)
}
// 护校经历 
const getSchoolExp = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/schoolExp', params)
}
// 抗疫经历 
const getKangYiExp = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/kangYiExp', params)
}
// 捐赠经历 
const getDonateExp = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/donateExp', params)
}
// 我的收藏
const getMyCollect = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/myCollect', params)
}
// 我的捐赠 
const getMyDonate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/myDonate', params)
}
// 我的兑换 
const getMyExchange = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/myExchange', params)
}

// 申请志愿者或义警
const apply = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/apply', params)
}

// 用户是否关注
const isSubscribe = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/isSubscribe')
}

// 用户信息修改
const updateInfo = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/updateInfo', params)
}

// 会费缴纳详情
const userIsPayment = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/user_order/isPayment', params)
}
// 会费缴费记录
const userMembershipRecord = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/user_order/membershipRecord', params)
}
// 会费缴费支付
const userPlaceOrder = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/user_order/placeOrder')
}

// 是否关注抖音
const tiktokIsAttention = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/tiktok/isAttention', params)
}

// 关注抖音
const tiktokStore = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/tiktok/store', params)
}

// 抖音弹窗不在提醒接口
const tiktokDotNotice = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/tiktok/dotNotice', params)
}

// 修改信息所属队伍 当前所在区县下的全部队伍
const ucenter = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/ucenter/selectDepartment', params)
}

// 首页商城弹窗
const toast = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/toast', {params})
}

// 学历列表
const levelList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/ucenter/getLevelList', {params})
}

// 职业接口
const jobList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/ucenter/getJobList', {params})
}

// 获取地址子集
const address = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/ucenter/getAddress', {params})
}

// 获取地址子集
const subDepartments = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/sub_departments', params)
}

// 活动分类列表
const activityGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/activity/group', {params})
}

// 活动经历
const activityRecord = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/activity/record', {params})
}

// 数据统计
const statistics = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/data/statistics', {params})
}

// 义警证件数据统计
const type = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/activity/type', {params})
}

// 义警证件年度统计
const annual = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/annual/statistics', {params})
}

// 义警证件服务统计
const service = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/service/statistics', {params})
}

// 义警降级
const demotion = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/demotion', {params})
}

// 审核义警总数
const audiTtotal = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/audit/total', {params})
}

// 审核义警列表
const audiList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/audit/list', {params})
}

// 审核义警进度
const auditProgress = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/audit/progress', {params})
}

// 审核义警
const audit = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/audit/index', params)
}

// 用户基本信息V2
const relationUser = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/user/info', {params})
}

// 义警申请状态
const auditStatus = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/audit/audit/status', {params})
}

// 大队列表
const department = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/relation/department', {params})
}

// 区县列表
const county = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/district/county', {params})
}

// 待审核列表
const activityAuditAwait = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/activity_audit/await', {params})
}

// 已审核列表
const activityAuditAudited = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/activity_audit/audited', {params})
}

// 详情
const activityAuditDetails = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/activity_audit/details', {params})
}

// 待审核数量
const activityAuditUnread = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/activity_audit/unread', {params})
}

// 任务审核
const activityAuditPresent = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity_audit/present', params)
}


export default {
	getUserInfo,
	getUserCenter,
	getLoveLevelDetail,
	getUserBasicInfo,
	getSchoolExp,
	getKangYiExp,
	getMyCollect,
	getMyDonate,
	getMyExchange,
	getDonateExp,
	loginLog,
	apply,
	isSubscribe,
	updateInfo,
	userIsPayment,
	userMembershipRecord,
	userPlaceOrder,
	tiktokStore,
	tiktokDotNotice,
	tiktokIsAttention,
	ucenter,
	toast,
	levelList,
	jobList,
	address,
	subDepartments,
	activityGroup,
	activityRecord,
	statistics,
	type,
	annual,
	service,
	demotion,
	audiTtotal,
	audiList,
	auditProgress,
	audit,
	relationUser,
	auditStatus,
	department,
	county,
	activityAuditAwait,
	activityAuditAudited,
	activityAuditDetails,
	activityAuditUnread,
	activityAuditPresent
}
