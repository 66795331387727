const letter = {
    namespaced: true,
    state: {
        unreadTotal: 0
    },
    mutations: {
        setUnreadTotal(state, unreadTotal) {
            state.unreadTotal = unreadTotal
        },
        decUnreadTotal(state) {
            if (state.unreadTotal > 0) {
                state.unreadTotal--;
            }
        }
    }
}

export default letter
