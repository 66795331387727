<template>
	<div class="list" @scroll="onScroll">
		<div class="list-body">
			<slot></slot>
		</div>
		<div class="list-footer">
			<div class="list-loading" v-if="_loading == true && _finished == false && _isEmpty == false">
				<van-loading type="spinner" size="20px">加载中</van-loading>
			</div>
			<div class="list-finished" v-if="_finished == true && _isEmpty == false">
				<div class="list-finished-text">已经到底啦</div>
			</div>
			<div class="list-empty" v-if="_loading == false && _isEmpty == true">
				<van-empty :image="require('@/assets/img/empty.png')" description="这里是空的哦" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			_isEmpty: false,
			_loading: false,
			_finished: false
		};
	},
	props: {
		isEmpty: {
			default: false
		},
		loading: {
			default: false
		},
		finished: {
			default: false
		},
		scrollView: {
			default: ''
		}
	},
	created() {
		this._isEmpty = this.isEmpty;
		this._loading = this.loading;
		this._finished = this.finished;
	},
	watch: {
		isEmpty() {
			this._isEmpty = this.isEmpty;
		},
		loading() {
			this._loading = this.loading;
		},
		finished() {
			this._finished = this.finished;
		}
	},
	methods: {
		onScroll(event) {
			let target = event.target;
			let clientHeight = document.querySelector('.list-body').clientHeight;
			let isScrollBottom = target.scrollTop >= clientHeight - (target.clientHeight + 50);

			if (
				isScrollBottom &&
				this._loading == false &&
				this._finished == false &&
				this._isEmpty == false
			) {
				this.$emit('onLoad');
			}
		}
	}
};
</script>

<style lang="less" scoped>
.line-after-before {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateX(-100%);
		width: 60px;
		height: 1px;
		background-color: #ddd;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateX(100%);
		width: 60px;
		height: 1px;
		background-color: #ddd;
	}
}

.list {
	width: 100%;
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	
	.list-loading {
		padding: 10px 0;
		text-align: center;
		box-sizing: border-box;

		.van-loading {
			display: inline-block;
			padding: 0 20px;
			.line-after-before();
		}
	}

	.list-finished {
		padding: 10px 0;
		text-align: center;

		.list-finished-text {
			font-size: 14px;
			color: #969799;
			display: inline-block;
			padding: 0 20px;
			.line-after-before();
		}
	}

	.list-empty {
		/deep/.van-empty__image {
			width: 100px;
			height: 100px;
		}
	}
}
</style>
