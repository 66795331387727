/**
 * Vant组件
 */

import Vue from 'vue'

import {
	Button,
	Toast,
	Swipe,
	SwipeItem,
	Badge,
	Grid,
	GridItem,
	Tabbar,
	TabbarItem,
	Loading,
	Empty,
	Icon,
	Field,
	Uploader,
	Popup,
	Dialog,
	Checkbox,
	ImagePreview,
	Circle,
	DropdownMenu,
	DropdownItem,
	Picker,
	List,
	Overlay,
	Form,
	Radio,
	RadioGroup,
	Sidebar,
	SidebarItem,
	Stepper,
	SwipeCell,
	DatetimePicker,
	Calendar,
	Cascader,
	Area,
	Search,
	ActionSheet
} from 'vant';

Vue.use(Cascader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Badge);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(Circle);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Picker);
Vue.use(List);
Vue.use(Overlay);
Vue.use(Form);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Stepper);
Vue.use(SwipeCell);
Vue.use(ImagePreview);
Vue.use(DatetimePicker);
Vue.use(Calendar);
Vue.use(Area);
Vue.use(Search);
Vue.use(ActionSheet);

Toast.setDefaultOptions('loading', {
	duration: 0,
	transition: 'none',
	forbidClick: true,
	loadingType: 'spinner'
});

Dialog.setDefaultOptions({
	confirmButtonColor: "#3377FF"
});

export default {
	Toast,
	Dialog,
	ImagePreview
}
