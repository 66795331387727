import Config from "@/vendor/config"
import Request from "@/api/request"
import Store from "@/store/store"

const login = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/login', params)
}

const sendText = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendText', params)
}

const sendImage = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendImage', params)
}

const sendVoice = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendVoice', params)
}

const sendRevoke = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendRevoke', params)
}

const enterGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/enterGroup', params)
}

const leaveGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/leaveGroup', params)
}

const getHistory = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/getHistory', params)
}

const getGroupDetail = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/getDetail', params)
}

const getGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/getGroup', params)
}

// 刷新群组，配合Vuex
const refreshGroup = () => {
	return new Promise((resolve, reject) => {
		getGroup().then(result => {
			Store.commit('group/setGroup', result.data);
			resolve(result);
		}).catch(error => {
			console.log("群组刷新失败: " + error);
			reject(error)
		})
	})
}

export default {
	login,
	getGroup,
	sendText,
	sendImage,
	sendVoice,
	sendRevoke,
	enterGroup,
	leaveGroup,
	getHistory,
	getGroupDetail,
	refreshGroup,
}