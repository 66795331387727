import Config from "@/vendor/config"
import Request from "@/api/request.js"

const getTodayDuty = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/duty')
}

const getUserCount = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/sysCount')
}

// 首页爱心传递
const donateIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/index', params)
}

const companies = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/companies', params)
}

const getViewCount = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/viewCount')
}

const getIndexNews = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/article/index')
}

const getIndexActivity = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/index', params)
}

const getWeChatJssdk = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/wxJsSdk', params)
}

const getIndexTask = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/index')
}

const departmentsTree = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/departmentsTree', params)
}

/****注册*********/
const registerSmsCode = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/sms/code', params)
}

const registerCheckCode = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/sms/check', params)
}

const registerUserInfo = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/register', params)
}

const registerFaceVerify = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/ucenter/authentication', params)
}
const indexNotices = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/system/notices', params)
}

const indexUnread = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/system/notice/unread', params)
}
export default {
	getTodayDuty,
	getUserCount,
	getViewCount,
	getIndexNews,
	getIndexTask,
	getWeChatJssdk,
	getIndexActivity,
	registerSmsCode,
	registerCheckCode,
	registerUserInfo,
	registerFaceVerify,
	departmentsTree,
	donateIndex,
	companies,
	indexUnread,
	indexNotices
}
