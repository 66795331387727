const keepAll = ['Index', 'Activity', 'SignIn', 'Message', 'User'];

export default {
	namespaced: true,
	state: {
		keep: [],
	},
	mutations: {
		setKeep(state, name) {
			if (state.keep.indexOf(name) === -1) {
				state.keep.push(name)
			}
		},
		removeKeep(state, name) {
			if (keepAll.indexOf(name) === -1) {

				let index = state.keep.findIndex(e => {
					return e === name
				});

				if (index !== -1) {
					state.keep.splice(index, 1)
				}
			}
		},
		clearKeep(state) {
			state.keep = []
		}
	}
}
