
// 环境检测
const isDev = process.env.NODE_ENV === "development"

// 全局登录状态
let isLoging = false

export default {
	isLoging,
	isDev
}
