import webStorage from "web-storage-cache";

const sessionStorage = new webStorage({
	storage: "sessionStorage",
	exp: Infinity
})

const localStorage = new webStorage({
	storage: "localStorage",
	exp: Infinity
})

export default {
	sessionStorage,
	localStorage
}
