
import Config from "@/vendor/config"
import Request from "@/api/request.js"

const chatMembers = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/chat/members', params)
}

const chatLoveRank = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/chat/group/loveRank', params)
}

const myLetter = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/letter/myLetter', params)
}

const letterDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/letter/detail', params)
}

const letterActivities = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/letter/activities', params)
}

const districtLoveRank = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/district/loveRank/area', params)
}

const letterUnReadCount = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/letter/unReadCount', params)
}

export default {
	chatMembers,
	chatLoveRank,
	myLetter,
	letterDetail,
	letterActivities,
	districtLoveRank,
	letterUnReadCount
}
