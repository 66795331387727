import Axios from "axios"
import TokenHelper from '@/vendor/token';
import DistrictHelper from '@/vendor/district';
import md5 from 'js-md5'

const http = Axios.create({
	timeout: 10000,
})

const requestList = new Set()

http.interceptors.request.use((config) => {
	
	config.headers["Token"] = TokenHelper.getToken("_TOKEN_")
	config.headers["Districtid"] = DistrictHelper.getDistrictId()
	
	if(config.url != "api/ucenter/userInfo") {
		config.cancelToken = new Axios.CancelToken(e => {
			
			let key = getRequestKey(config)
			
			if (requestList.has(key)) {
				e(key)
			} else {
				requestList.add(key)
			}
		})
	}
	
	return config;
});

http.interceptors.response.use(response => {

	requestList.delete(getRequestKey(response.config))

	return Promise.resolve(response.data)

}, error => {
	
	if(Axios.isCancel(error)) {
		requestList.delete(error.message)
	} else {
		requestList.delete(getRequestKey(error.config))
	}

	return Promise.reject(error)
})

function getRequestKey(config) {

	let url = config.url;
	let data = config.data;

	if (typeof data == 'object') {
		data = JSON.stringify(data);
	}

	return md5(url + data)
}

export default http
