import IndexApi from '@/api/index';
import VantVendor from '@/vendor/vant';

class record {

	isRecording = false;
	recordStartTime = 0;
	recordStopTime = 0;
	minRecordTime = 0;

	constructor(minRecordTime = 1000) {
		this.minRecordTime = minRecordTime
	}

	startRecord() {
		return new Promise((resolve, reject) => {
			wx.startRecord({
				success: result => {

					this.isRecording = true;
					this.recordStartTime = new Date().getTime();

					resolve(result)
				},
				error: error => {
					reject(error)
				}
			});
		})
	}

	stopRecord() {
		return new Promise((resolve, reject) => {
			wx.stopRecord({
				success: (result) => {

					this.isRecording = false;
					this.recordStopTime = new Date().getTime();

					if (this.recordStopTime - this.recordStartTime < 1000) {
						reject({
							errMsg: "录音时间太短"
						})
					}
					resolve(result)
				},
				error: (error) => {
					reject(error)
				}
			});
		})
	}
}


const jssdk = {
	initialize() {

		return new Promise((resolve, reject) => {

			let params = {
				url: location.href.split('#')[0]
			}

			let jsApiList = [
				'startRecord',
				'stopRecord',
				'onVoiceRecordEnd',
				'uploadVoice',
				'getLocation',
				'onMenuShareTimeline',
				'updateTimelineShareData',
				'updateAppMessageShareData',
				'onMenuShareAppMessage',
				'chooseWXPay',
				'wx-open-launch-weapp',
			];

			IndexApi.getWeChatJssdk(params)
				.then(result => {
					wx.config({
						debug: false,
						appId: result.data.appId,
						timestamp: result.data.timestamp,
						nonceStr: result.data.nonceStr,
						signature: result.data.signature,
						jsApiList: jsApiList,
						openTagList: ['wx-open-launch-weapp']
					});
					
					wx.ready(() => {
						resolve()
					})

					wx.error(function(error) {
						reject(error)
					});
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}

const getLocation = () => {
	return new Promise((resolve, reject) => {
		wx.getLocation({
			type: 'gcj02',
			success: result => {
				resolve(result);
			},
			error: error => {
				VantVendor.Toast('定位失败');
			}
		});
	})
}


export default {
	record,
	jssdk,
	getLocation
}
