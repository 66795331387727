const tabbar = {
	namespaced: true,
	state: {
		active: 0,
		isShowDot: false,
	},
	mutations: {
		showDot(state){
			state.isShowDot = true;
		},
		hideDot(state){
			state.isShowDot = false;
		},
		setActive(state, active) {
			state.active = active
		}
	}
}

export default tabbar