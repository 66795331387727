import Storage from "@/vendor/storage"

const prefix = "_TOKEN_";

const token = {
	setToken(token) {
		Storage.localStorage.set(prefix, token);
	},
	getToken() {
		return Storage.localStorage.get(prefix);
	}
}

export default token