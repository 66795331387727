<template>
	<div
		class="loading-page"
		v-if="isLoading == true"
		@click.prevent="onClick"
		@touchstart.prevent="onTouch"
	>
		<div class="loading-page-container">
			<van-loading vertical size="36px">加载中</van-loading>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		loading: {
			default: true
		}
	},
	data() {
		return {
			isLoading: true
		};
	},
	created() {
		this.isLoading = this.loading;
	},
	watch: {
		loading(newVal, oldVal) {
			if (newVal == false) {
				setTimeout(() => {
					this.isLoading = newVal;
				}, 100);
			} else {
				this.isLoading = newVal;
			}
		}
	},
	methods: {
		onTouch() {
			return false;
		},
		onClick() {
			return false;
		}
	}
};
</script>

<style lang="less" scoped>
.loading-page {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 1000;

	.loading-page-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
