import Vue from 'vue'
import App from './App.vue'
import Vant from '@/vendor/vant'
import Store from '@/store/store'
import Router from '@/router/router'
import Flexible from '@/extend/flexible'
import Global from '@/vendor/global.js'
import Eruda from 'eruda'



if (Global.isDev) {
	// ----------调试----------
	// Eruda.init()
	// ----------调试--end--------
}

Vue.config.ignoredElements.push('wx-open-launch-weapp')

import WList from '@/components/List';
import WNavTab from '@/components/NavTab';
import LoadingPage from '@/components/LoadingPage';

Vue.component('w-list', WList)
Vue.component('w-navTab', WNavTab)
Vue.component('loading-page', LoadingPage)

new Flexible({
	baseSize: 100,
	maxWidth: 1920,
	designWidth: 375,
})

Vue.config.productionTip = false

new Vue({
	store: Store,
	router: Router,
	render: h => h(App),
}).$mount('#app')
