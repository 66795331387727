<template>
	<div id="app">
		<keep-alive :include="include">
			<router-view v-if="isLoading == false" :class="['router-view', className()]" />
		</keep-alive>
	</div>
</template>

<script>
import '@/assets/less/global.less';
import Config from '@/vendor/config';
import UserApi from '@/api/user';
import ServerApi from '@/api/server';
import messageApi from '@/api/message';
import VantVendor from '@/vendor/vant';
import WebSocketIo from '@/extend/webSocketIO';
import WechatVendor from '@/vendor/wechat';
import District from '@/vendor/district'
import Event from '@/store/event';

export default {
	name: 'App',
	data() {
		return {
			isLoading: true
		};
	},
	created() {
		// this.isLoading = false;
		VantVendor.Toast.loading('加载中');
		UserApi.loginLog();
		this.createSocket();
		document.title = District.getDistrictName() + (District.getDistrictId() == 1 ? '平安义警协会' : '平安义警分会')
	},
	computed: {
		include() {
			return this.$store.state.keep.keep;
		}
	},
	provide() {
		return {
			isCity: this.isCity,
			className: this.className,
			themeColor: this.themeColor,
			isDistrict: this.isDistrict,
			districtType: this.districtType
		};
	},
	methods: {
		isCity() {
			return this.districtType() == 1;
		},
		isDistrict() {
			return this.districtType() == 2;
		},
		themeColor() {
			return this.districtType() == 1 ? '#3377FF' : '#FF6969';
		},
		districtType() {
			return this.$store.state.district.type;
		},
		className(prefix = '') {
			return prefix + this.$store.state.district.className;
		},
		createSocket() {
			window.socketIo = new WebSocketIo({
				connection: Config.imWsUrl,
				reconnectionDelay: 3000
			});

			socketIo.addListener('onMessage', 'index', this.onMessage);
		},
		createApp(client_id) {
			VantVendor.Toast.clear();
			this.isLoading = false;
			if (process.env.NODE_ENV !== "development") {
				VantVendor.Toast.clear();
				this.isLoading = false;
			}

			let userApi = UserApi.getUserInfo();
			let groupApi = ServerApi.getGroup();
			let loginApi = ServerApi.login({ client_id: client_id });
			let jssdkApi = WechatVendor.jssdk.initialize();
			let letterApi = messageApi.letterUnReadCount();
			
			Promise.all(
				[userApi, groupApi, letterApi, loginApi, jssdkApi]
			).then(result => {
				let [userInfo, group, letter] = result;
				this.$store.commit('group/setGroup', group.data);
				this.$store.commit('user/setUserInfo', userInfo.data);
				this.$store.commit('letter/setUnreadTotal', letter.data.unReadCount);
				
				/**
				 * 触发登录成功事件
				 */
				Event.$emit('onLogin', userInfo.data)
				
				VantVendor.Toast.clear()
				this.isLoading = false;
			}).catch(error => {
				console.log(error)
				this.isLoading = false;
			});
		},
		onMessage(data) {
			const typeMap = {
				ping: () => {
					console.log(data);
				},
				login: () => {
					window.client_id = data.client_id;
					this.createApp(client_id);
				},
				default: () => {

					/**
					 * 如果接收消息非当前用户所发送则计入未读消息
					 */
					if (data.user_id != this.$store.state.user.userInfo.id) {
						this.$store.commit('group/setUnreadByGroupId', data.department_id);
					}

					/**
					 * 设置群组最后一条信息
					 */
					this.$store.commit('group/setMessageByGroupId', data);
				}
			};

			if (typeMap[data.type]) {
				typeMap[data.type]();
			} else {
				typeMap['default']();
			}
		}
	}
};
</script>

<style lang="less">
body {
	user-select: none;
}

.route-leave {
	display: none;
}

.van-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.van-dialog {
	border-radius: 6px;
}

::-webkit-scrollbar {
	display: none;
}

p {
	padding: 5px 0;
	margin: 0;
}
</style>
