export default {
	namespaced: true,
	state: {
		userInfo: {},
	},
	mutations: {
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo
		},
		updateUserInfo(state, key, value) {
			state.userInfo[key] = value
		},
		clearUserInfo(state) {
			state.userInfo = {}
		}
	}
}