
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from '@/store/user'
import keep from '@/store/keep'
import group from '@/store/group'
import letter from '@/store/letter'
import tabbar from '@/store/tabbar'
import district from '@/store/district'
import shopping from '@/store/shopping'

const store = new Vuex.Store({
	modules: {
		user: user,
		keep: keep,
		group: group,
		tabbar: tabbar,
		letter: letter,
		district: district,
		shopping: shopping,
	}
})

export default store