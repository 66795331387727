import Http from "@/vendor/http"
import Router from "@/router/router"
import VantVendor from "@/vendor/vant"
import ActionVendor from "@/vendor/action"

let passStatusCodeMaps = {
	200: (resolve, reject, result) => {
		return resolve(result)
	},
	1001: (resolve, reject, result) => {
		ActionVendor.jumpToLogin()
	},
	1002: (resolve, reject, result) => {
		ActionVendor.showRegisterConfirm();
	},
	1009: (resolve, reject, result) => {
		if (Router.currentRoute.name != 'Blacklist') {
			Router.replace({
				name: "Blacklist"
			})
		}
	},
	3003: () => {
		ActionVendor.showApplyConfirm();
	},
	3010: () => {
		ActionVendor.showRenewalConfirm();
	},
	// 没权限访问的状态码
	3011: () => {
		ActionVendor.showPermission()
	}
};

let failStatusCodeMaps = {
	'ECONNABORTED': (resolve, reject, result) => {
		VantVendor.Dialog.alert({
			title: '错误',
			message: '网络超时',
		})
	}
};

export default {
	get(url, ...params) {
		return Http.get(url, ...params)
	},
	post(url, ...params) {
		return Http.post(url, ...params)
	},
	request(closure, ...args) {
		return new Promise((resolve, reject) => {
			closure(...args)
				.then(result => {
					if (passStatusCodeMaps[result.code]) {
						return passStatusCodeMaps[result.code](resolve, reject, result);
					}
					return reject(result);
				})
				.catch(error => {
					if (failStatusCodeMaps[error.code]) {
						return failStatusCodeMaps[error.code](resolve, reject, error);
					}
					return reject(error);
				});
		})
	},
	setBaseUrl(baseUrl) {
		Http.defaults.baseURL = baseUrl
		return this
	}
}
